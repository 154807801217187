
import { lazy } from "react"
interface routerInter {
    children?: Array<routerInter>
    // element?: React.ReactNode
    element?: any
    component?: any
    path?: string
    auth?: boolean
    redirect?:string
    title:string
}

 const routes:routerInter[] = [
    {
        path:'/',
        redirect:'/shopping_mall_home',
        title:'趣味测试' 
    },
    // {
    //     // 测试
    //     path:'/index',
    //     element:lazy(() => import("../pages/index/index"))
    // },

   
    {//短剧
        path:'/playlet',
        element:lazy(() => import("../pages/playlet")),
        title:'继续看剧抽红包' 
    },
    {//短剧
        path:'/playlet_v2',
        element:lazy(() => import("../pages/playlet_v2")),
        title:'短剧抽手机' 
    },
    
    {// 支付过度页
        path:'/payment_transition',
        element:lazy(() => import("../pages/payment_transition")),
        title:'短剧抽手机' 
    },
   

    {
        path:'/attractiveness/payment/succeed/:id',
        element:lazy(() => import("../pages/attractiveness/succeed")),
        title:'测画像赢手机' 
    },
    {
        path:'/attractiveness/payment/succeed/result/:id',
        element:lazy(() => import("../pages/attractiveness/result")),
        title:'' 
    },
    {//塔罗抽手机
        path:'/tarot_activity_v1',
        element:lazy(() => import("../pages/tarot_activity_v1")),
        title:'测画像赢手机' 
    },


    // 抽奖部分
    {
        path:'/draw_lottery_raffle/:id',
        element:lazy(() => import("../pages/draw_lottery_raffle")),
        title:'测画像赢手机' 
    },
    {
        path:'/draw_lottery_raffle/select/:id',
        element:lazy(() => import("../pages/draw_lottery_raffle/select")),
        title:'测画像赢手机' 
    },
    {
        path:'/draw_lottery_raffle/result/:id',
        element:lazy(() => import("../pages/draw_lottery_raffle/result")),
        title:'测画像赢手机' 
    },
    {
        path:'/draw_lottery_raffle/get_award/:id',
        element:lazy(() => import("../pages/draw_lottery_raffle/get_award")),
        title:'测画像赢手机' 
    },
    {
        path:'/draw_lottery_raffle/receive_successfully/:id',
        element:lazy(() => import("../pages/draw_lottery_raffle/receive_successfully")),
        title:'测画像赢手机' 
    },

    //选择标签
    {
        path:'/selectLabel/:id',
        element:lazy(() => import("../pages/selectLabel")),
        title:'测画像赢手机' 
    },
    {
        path:'/selectLabel/gender/:id',
        element:lazy(() => import("../pages/selectLabel/gender")),
        title:'测画像赢手机' 
    },

    // 退款
    {
        path:"refund/:id",
        element:lazy(() => import("../pages/refund")),
        title:'退款' 
    },




    {
        path:'/shopping_mall_home',
        element:lazy(() => import("../pages/shopping_mall_home")),
        title:'趣味测试' 
    },
    {
        path:'/test_v1',
        element:lazy(() => import("../pages/test_v1")),
        title:'趣味测试' 
    },
    {
        path:'/test_v1_result',
        element:lazy(() => import("../pages/test_v1/result")),
        title:'趣味测试' 
    },
    {
        path:'/test_v2',
        element:lazy(() => import("../pages/test_v2")),
        title:'趣味测试' 
    },
    {
        path:'/test_v2_result',
        element:lazy(() => import("../pages/test_v2/result")),
        title:'趣味测试' 
    },
    {
        path:'/test_v3',
        element:lazy(() => import("../pages/test_v3")),
        title:'趣味测试' 
    },
    {
        path:'/test_v3_result',
        element:lazy(() => import("../pages/test_v3/result")),
        title:'趣味测试' 
    },
    {
        path:'/test_v4',
        element:lazy(() => import("../pages/test_v4")),
        title:'趣味测试' 
    },
    {
        path:'/test_v4_result',
        element:lazy(() => import("../pages/test_v4/result")),
        title:'趣味测试' 
    },
    {
        path:'/test_v5',
        element:lazy(() => import("../pages/test_v5")),
        title:'趣味测试' 
    },
    {
        path:'/test_v5_result',
        element:lazy(() => import("../pages/test_v5/result")),
        title:'趣味测试' 
    },



    {
        path:'/portrait_true_love',
        element:lazy(() => import("../pages/portrait_true_love")),
        title:'真爱画像' 
    },
    // 支付页面
    {
        path:'/common_payment/:id',
        element:lazy(() => import("../pages/common_payment")),
        title:'支付页' 
    },
    // 微信投诉页面
    {
        path:'/complaint/:id',
        element:lazy(() => import("../pages/complaint")),
        title:'投诉' 
    },
    {
        path:'/complaint/serve_record/:id',
        element:lazy(() => import("../pages/complaint/serve_record")),
        title:'服务记录' 
    },
    {
        path:'/complaint/list_details/:id/:type',
        element:lazy(() => import("../pages/complaint/list_details")),
        title:'投诉' 
    },
    {
        path:'/complaint/list_details/complaint_form/:id/:type',
        element:lazy(() => import("../pages/complaint/complaint_form")),
        title:'投诉' 
    },
    {
        path:'/complaint/list_details/complaint_form/result/:id/:type',
        element:lazy(() => import("../pages/complaint/result")),
        title:'投诉处理' 
    },
    {
        path:'/complaint/list_details/complaint_form/result/result_details/:id/:type',
        element:lazy(() => import("../pages/complaint/result_details")),
        title:'详情' 
    },


    {
        path:'/wechat_succeed_common',
        element:lazy(() => import("../pages/wechat_succeed_common")),
        title:'支付成功' 
    },

    {
        //拦截商城
        path:'/shopping',
        element:lazy(() => import("../pages/shopping")),
        title:'商城' 
    },
    

    // {
    //     path:'/home_marriage_test/payment/:id/:type',
    //     component:lazy(() => import("../pages/home_marriage_test/payment"))
    // },
    // {
    //     path: "*",
    //     component: lazy(() => import('../pages/error'))
    // },
]

export default routes